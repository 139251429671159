@import 'material-icons/iconfont/filled.css'
@import 'material-icons/iconfont/outlined.css'
@tailwind base
@tailwind components
@tailwind utilities

:root
  --ra-violet: #613cb7
  --ra-violet-100: #ede9fe
  --ra-violet75: #c583fb
  --ra-black: #212121
  --ra-white: #fff
  --ra-red: #cc3546
  --ra-redd: #e59aa3
  --ra-text: #7B5FE7
  --ra-green: #16b542
  --ra-green75: #8ef1aa
  --ra-light-green: #89d18c
  --ra-orange: #fcae07
  --ra-op: #1C1B1F
  --ra-grey: #6B6B6B
  --ra-gray: #EAEAEA
  --ra-textgray: #4b5563
  --ra-overspeed: #c03e00
  --ra-brake: #ff7517
  --ra-corner: #ffbe00
  --ra-accel: #f89880
  --ra-speedcap: #ff0000
  --ra-idlecap: #6DBDFF
  --ra-light-violet: #f1e1fe
  --ra-dark-grey: #353535
  --ra-chart-green: #17A2B8
  --ra-lavender: #f8f2fc
  --ra-light-blue: #d4e9fc
  --ra-yellow: #ffff00
  --ra-idling: #f59e0b
  --ra-link: #3498db
  --ra-link-hover: #2980b9
  --ra-link-active: #1c6690

@font-face
  font-family: "ra-poppins"
  src: url('assets/fonts/Poppins-Regular.ttf')

.raf
  font-family: "ra-poppins" !important

.fx
  display: flex
  box-sizing: border-box
.fxr
  @extend .fx
  flex-direction: row
.fxc
  @extend .fx
  flex-direction: column
.fxac
  justify-content: center
  align-items: center
  align-content: center
.fxhc
  margin-left: auto
  margin-right: auto
  display: flex
.fxw
  flex-wrap: wrap
.fxnw
  flex-wrap: nowrap
.fxwr
  flex-wrap: wrap-reverse

.space-between
  justify-content: space-between
.justify-center
  justify-content: center

.items-center
  align-items: center
.grid
  display: grid

.flex-wrap
  flex-wrap: wrap

.flex-col-xs
  @media screen and (max-width: 599px)
    flex-direction: column // Switch from flex-row to flex-col on mobile

button, span, input, div
  @extend .raf

input:-internal-autofill-selected
  background-color: var(--ra-white)

.ra-input.mat-form-field-appearance-outline
  height: 60px
  width: 100%
  max-width: 100%
  .mat-mdc-select
    height: 20px
    .mat-mdc-select-trigger
      height: 20px
    .mat-mdc-select-arrow-wrapper
      height: 20px
  // .mdc-text-field
  .mat-mdc-text-field-wrapper
    padding-left: 5px
    height: 40px
    background-color: var(--ra-white)
    .mat-mdc-form-field-flex
      height: 40px
      background-color: var(--ra-white)
      .mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix
        max-height: 40px
        max-width: 40px
        button
          padding: 0px
          height: 40px
          max-width: 40px
          max-height: 40px
      .mat-mdc-form-field-infix
        height: 40px
        min-height: 20px
        max-height: 40px
        padding-top: 10px
        padding-bottom: 10px
    .mat-icon
      padding: 10px
      max-height: 20px
    button .mat-icon
      padding: 0px
  .mat-mdc-form-field-subscript-wrapper
    height: 20px
    .mat-mdc-form-field-error-wrapper
      height: 20px
      padding: 0px
      .mat-mdc-form-field-error
        font-size: 12px
        span
          position: absolute
.ra-input-ta.mat-form-field-appearance-outline
  font-size: 14px
  width: 100%
  max-width: 100%
  .mat-mdc-text-field-wrapper
    background-color: var(--ra-white) !important
    color: var(--ra-black) !important
  .mat-form-field-appearance-outline .mat-form-field-outline
    color: var(--ra-violet) !important

.ra-input-ta-ai.mat-form-field-appearance-outline
  font-size: 14px
  width: 100%
  max-width: 100%
  .mat-mdc-text-field-wrapper
    background-color: var(--ra-white) !important
    color: var(--ra-black) !important
  .mat-form-field-appearance-outline .mat-form-field-outline
    color: var(--ra-violet) !important
  /* Change the border color */
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing
    border-color: var(--ra-violet) !important
  // Focused border
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__trailing
    border-color: var(--ra-violet) !important

.ra-input.mat-mdc-form-field
  // height: 60px
  width: 100%
  .mat-date-range-input-inner
    font-size: 14px

// No subscript input
.no-hint.mat-form-field-appearance-outline
  height: fit-content !important
.no-hint .mat-mdc-form-field-subscript-wrapper
  display: none

.ra-button
  height: 40px !important
  font-size: 14px !important

.submit-button
  @extend .ra-button
  color: var(--ra-white) !important
  &.mdc-button
    background-color: var(--ra-red) !important
  &.mat-mdc-button-disabled
    background-color: var(--ra-redd) !important
.ra-icon-button
  @extend .ra-button
  color: var(--ra-white) !important
  &.mdc-button
    background-color: var(--ra-violet) !important
  &.mat-mdc-button-disabled
    background-color: var(--ra-violet) !important

.cancel-button
  @extend .ra-button
  background-color: var(--ra-gray) !important
  &.mat-mdc-button-disabled
    // background-color: var(--ra-redd) !important
.upload-button
  @extend .ra-button
  color: var(--ra-white) !important
  background-color: var(--ra-grey) !important
  &.mat-mdc-button-disabled
    background-color: var(--ra-grey) !important

.add-button
  @extend .ra-button
  color: var(--ra-white) !important
  &.mdc-button
    background-color: var(--ra-text) !important
  &.mat-mdc-button-disabled
    background-color: var(--ra-grey) !important

.ra-input.mat-form-field-appearance-outline
  .ra-mat-icon-button
    color: var(--ra-red) !important
    max-width: 100% !important
    max-height: 100% !important
    padding: 0px !important

.ra-label
  @extend .ra-elli
  font-size: 14px
.ra-elli
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden
.ra-br
  word-break: break-all

.mat-mdc-checkbox
  .mdc-checkbox__background
    border-color: var(--ra-op) !important
    background-color: var(--ra-white) !important
  &.mat-mdc-checkbox-checked
    .mdc-checkbox__background
      border-color: var(--ra-green) !important
      background-color: var(--ra-white) !important
    .mdc-checkbox__checkmark
      color: var(--ra-green) !important
// .ra-map-checkbox
//   &input[type="checkbox"]
//     border-color: var(--ra-op) !important
//     background-color: var(--ra-white) !important
//   &input[type="checkbox"]:checked
//     border-color: var(--ra-green) !important
//     background-color: var(--ra-white) !important
.ra-card
  background-color: var(--ra-gray)
.ra-card-white
  background-color: var(--ra-white) !important

.ra-chip
  &.mat-mdc-standard-chip
    background-color: var(--ra-violet) !important
  .mat-mdc-chip-action-label
    color: var(--ra-white) !important

.ra-icon
  height: 20px !important
  width: 20px !important
.ra-add-icon
  color: var(--ra-black)
  @extend  .ra-icon
.ra-edit-icon
  color: var(--ra-text)
  @extend  .ra-icon
.ra-delete-icon
  color: var(--ra-red)
  @extend  .ra-icon

mat-dialog-container
  .mat-mdc-dialog-component-host
    min-width: inherit
    min-height: inherit
    width: inherit
    height: inherit
    .mat-mdc-dialog-content
      min-height: 20vh
      min-width: 50vw
#repair-request-vehicle-operational
  .mat-mdc-dialog-content
    min-height: 0
    min-width: 0
mat-tab-header
    background-color: var(--ra-gray)
.cv
  color: var(--ra-violet)
  background-color: var(--ra-white)

.mat-mdc-radio-button.ra-radio
  &.green
    --mdc-radio-selected-focus-icon-color: var(--ra-green)
    --mdc-radio-selected-hover-icon-color: var(--ra-green)
    --mdc-radio-selected-icon-color: var(--ra-green)
    --mdc-radio-selected-pressed-icon-color: var(--ra-green)
    --mat-radio-checked-ripple-color: var(--ra-green)
  &.orange
    --mdc-radio-selected-focus-icon-color: var(--ra-orange)
    --mdc-radio-selected-hover-icon-color: var(--ra-orange)
    --mdc-radio-selected-icon-color: var(--ra-orange)
    --mdc-radio-selected-pressed-icon-color: var(--ra-orange)
    --mat-radio-checked-ripple-color: var(--ra-orange)
  &.red
    --mdc-radio-selected-focus-icon-color: var(--ra-red)
    --mdc-radio-selected-hover-icon-color: var(--ra-red)
    --mdc-radio-selected-icon-color: var(--ra-red)
    --mdc-radio-selected-pressed-icon-color: var(--ra-red)
    --mat-radio-checked-ripple-color: var(--ra-red)
  &.purple
    --mdc-radio-selected-focus-icon-color: var(--ra-violet)
    --mdc-radio-selected-hover-icon-color: var(--ra-violet)
    --mdc-radio-selected-icon-color: var(--ra-violet)
    --mdc-radio-selected-pressed-icon-color: var(--ra-violet)
    --mat-radio-checked-ripple-color: var(--ra-violet)

.ra-bullet
  font-size: 24px
  font-weight: bold

#ra-map-search-input
  width: 350px
  height: 30px
  padding: 5px
  border: 2px solid var(--ra-violet)
  border-radius: 5px !important
  visibility: hidden
  &.show-input
    visibility: visible

@mixin ra-anim2($l: 0.95, $h: 1)
  animation: grow 2s ease-in-out infinite
  @keyframes grow
    80%
      transform: scale($l)
    90%
      transform: scale($h)
    100%
      transform: scale($l)
@mixin float
  @keyframes float
    50%
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6)
      transform: translateY(0px)
    80%
      box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2)
      transform: translateY(-20px)
    100%
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6)
      transform: translateY(0px)

@mixin sethw($pixels:0px)
  height: $pixels !important
  width: $pixels !important

.ra-link
  color: var(--ra-link)
  text-decoration: none
  cursor: pointer
  transition: color 0.3s ease
  font-weight: normal

  &:hover
    font-weight: bold
    color: var(--ra-link-hover)
    text-decoration: underline
    &:active
      color: var(--ra-link-active)

.ra-search-map
  height: 40px
  width: 70vw
  max-width: 70vh
  background-color: var(--ra-white)
  cursor: pointer
  font-size: 14px
  line-height: 18px
  text-align: left
  border: none
  border-top-left-radius: 20px
  border-bottom-left-radius: 20px
  padding-left: 10px
  padding-right: 40px
.ra-search-map-clear
  height: 40px
  width: 50px
  max-width: 10vh
  background-color: var(--ra-white)
  cursor: pointer
  font-size: 14px
  line-height: 18px
  text-align: center
  border: none
  border-top-right-radius: 20px
  border-bottom-right-radius: 20px
  padding-left: 10px
  padding-right: 40px

#ra-map
  .gm-style-iw
    &.gm-style-iw
      padding: 8px !important
      // background-color: var(--ra-chart-green)
    .gm-style-iw-chr
      .gm-style-iw-ch
        font-size: 14px
        font-weight: bold
        line-height: 24px
        padding-top: 0
      button
        @include sethw(24px)
        span
          @include sethw(24px)
          margin: 0 !important
          padding: 0 !important

  .gm-style-cc
    display: none
  .gm-style-mtc
    font-size: 14px !important
    button
      font-size: 14px !important
    label
      font-size: 14px !important

  &.vehicle-map
    .yNHHyP-marker-view
      background-color: white
      @include sethw()
    .IPAZAH-content-container
      @include sethw()
      .GMAMP-maps-pin-view
        @include sethw()
        svg
          @include sethw()
    gmp-advanced-marker
      &.yNHHyP-marker-view
        background-color: white
        @include sethw()
        .GMAMP-maps-pin-view
          @include sethw()
          svg
            @include sethw()
        .GMAMP-maps-pin-view > div
          @include sethw()
          .ra-office-hq
            // @include ra-anim2(0.95, 1)
            @include sethw(30px)
            svg
              @include sethw(30px)
              fill: var(--ra-white)
              border-radius: 50%
          .ra-marker
            pointer-events: all
            cursor: pointer
            position: absolute
          .ra-poi-marker
            pointer-events: all
            cursor: pointer
            position: absolute
            left: 0px
            top: 0px
            .ra-poi-pin
              width: 15px !important
              height: 15px !important
              border-radius: 50%
              border: 1px solid var(--ra-black)
              @extend .ra-anim
          .ra-search-marker
            pointer-events: all
            cursor: pointer
            position: absolute
            left: 0px
            top: 0px
            .ra-search-pin
              @extend .ra-anim
              @include sethw(15px)
              border-radius: 50%
              border: 1px solid var(--ra-black)
  &.vehicle-routes
    .yNHHyP-marker-view
      background-color: white
      @include sethw()
    .IPAZAH-content-container
      @include sethw()
      .GMAMP-maps-pin-view
        @include sethw()
        svg
          @include sethw()
    gmp-advanced-marker
      &.yNHHyP-marker-view
        background-color: white
        @include sethw()
        .GMAMP-maps-pin-view
          @include sethw()
          svg
            @include sethw()
        .GMAMP-maps-pin-view > div
          @include sethw()
          .ra-event-marker
            pointer-events: all
            cursor: pointer
            position: absolute
            top: -7.5px
            left: -7.5px
            .ra-event-pin
              @include sethw(15px)
              border-radius: 50%
              border: 1px solid var(--ra-black)
          .ra-point-marker
            &.ra-start
              position: absolute
              top: -25px
              left: -25px
            &.ra-end
              position: absolute
              top: -40px
              left: -10px
            .ra-point
              @include sethw(25px)
              svg
                @include sethw(50px)
  &.vehicle-timeline
    .yNHHyP-marker-view
      background-color: white
      @include sethw()
    .IPAZAH-content-container
      @include sethw()
      .GMAMP-maps-pin-view
        @include sethw()
        svg
          @include sethw()
    gmp-advanced-marker
      &.yNHHyP-marker-view
        background-color: white
        @include sethw()
        .GMAMP-maps-pin-view
          @include sethw()
          svg
            @include sethw()
        .GMAMP-maps-pin-view > div
          @include sethw()
          .ra-activity-icon
            @include sethw(20px)
            border-radius: 10px !important
            .ra-point
              @include sethw(20px)
              svg
                @include sethw(20px)
          .ra-event-marker
            pointer-events: all
            cursor: pointer
            position: absolute
            top: -7.5px
            left: -7.5px
            .ra-event-pin
              @include sethw(12px)
              border-radius: 50%
              border: 1px solid var(--ra-black)

  .ra-bg
    background-color: var(--ra-grey)
    color: var(--ra-white)

  .ra-marker
    width: 50px !important
    left: -25px !important
    top: -46px
    text-align: center
    .ra-flag
      svg
        fill: var(--ra-white)
    .ra-info
      display: none
      border: none
      @extend .ra-elli
      width: inherit !important
      background-color: var(--ra-grey)
      color: var(--ra-white)
      text-decoration: underline
      padding: 4px !important
      height: 18px
      line-height: 10px
      font-weight: bolder
      font-size: 12px
    .ra-unit-number
      @extend .ra-bg
      @extend .ra-elli
      width: inherit !important
      border: none
      border-top-left-radius: 5px
      border-top-right-radius: 5px
      padding: 4px !important
      height: 18px
      line-height: 10px
      font-size: 10px
    .ra-speed
      @extend .ra-bg
      @extend .ra-elli
      width: inherit !important
      border: none
      padding: 4px !important
      height: 18px
      line-height: 10px
      font-size: 10px
    .ra-direction
      display: none
      @extend .ra-bg
      @include sethw(15px)
      width: inherit !important
      border: none
      .ra-arrow
        @include sethw(15px)
        margin-left: auto
        margin-right: auto
        svg
          width: inherit !important
          height: inherit !important
    .arrow-down
      width: inherit !important
      height: 10px !important
      border-top: 10px solid var(--ra-grey)
      border-left: 25px solid transparent
      border-right: 25px solid transparent

    &.ra-moving.ra-alert, &.ra-idling.ra-alert
      .ra-unit-number
        border-top-left-radius: 0px !important
        border-top-right-radius: 0px !important
      .arrow-down
        border-top: 10px solid var(--ra-red)

    &.ra-moving
      top: -78px
      .ra-unit-number
        background-color: var(--ra-green)
      .ra-speed
        background-color: var(--ra-green)
      .ra-direction
        display: block
        background-color: var(--ra-green)
        svg
          fill: var(--ra-violet)
      .arrow-down
        border-top: 10px solid var(--ra-green)
    &.ra-idling
      .ra-unit-number
        background-color: var(--ra-idling)
      .ra-speed
        background-color: var(--ra-idling)
      .ra-direction
        display: none
        background-color: var(--ra-idling)
        svg
          fill: var(--ra-violet)
      .arrow-down
        border-top: 10px solid var(--ra-idling)
    &.ra-alert
      top: -64px
      .ra-info
        display: block
        color: var(--ra-white)
        background-color: var(--ra-red)
        border-top-left-radius: 5px !important
        border-top-right-radius: 5px !important
      .ra-unit-number
        background-color: var(--ra-red)
        border-top-left-radius: 0px !important
        border-top-right-radius: 0px !important
      .ra-speed
        background-color: var(--ra-red)
      .ra-direction
        display: none
        background-color: var(--ra-red)
        svg
          fill: var(--ra-white)
      .arrow-down
        border-top: 10px solid var(--ra-red)

  .ra-cluster
    @include sethw(30px)
    border-radius: 15px
    line-height: 30px
    color: var(--ra-white)
    background-color: var(--ra-violet)
    text-align: center
    .ra-cluster-count
      width: inherit !important
      height: inherit !important

  .ra-marker-info
    // background-color: var(--ra-violet)
    .ra-marker-m
      .ra-marker-routes
        color: var(--ra-violet)
        font-weight: bold

  .ra-button
    height: 30px
    width: 100%
    padding: 5px
    color: var(--ra-white) !important
    background-color: var(--ra-red) !important

  .ra-poi-info
    font-size: 12px !important
    .ra-poi-title
      font-size: 12px !important
    .ra-poi-button
      font-size: 12px !important

.side-menu
  // padding: 10px
  // border-right: 1px solid var(--ra-gray)

  .menu-item
    background-color: var(--ra-gray)
    margin-left: 5px
    margin-bottom: 5px
    padding: 5px
    border-radius: 5px
    font-size: 12px
  .side-menu-nav-icon
    // font-size: 14px
    // height: 18px
    // width: 18px
    mat-icon
      color: var(--ra-green)
  .selected-menu
    font-weight: bold
    background-color: var(--ra-violet)
    color: var(--ra-white)
    &.ra-card-white
      color: var(--ra-black)

  .ongoing
    background-color: var(--ra-green)

.route-event-count
  @include sethw(30px)
  border-radius: 15px
  text-align: center
  div
    // align-items: center
    // padding-top: 5px
    line-height: 14px

.overspeed
  background-color: var(--ra-overspeed)
.brake
  background-color: var(--ra-brake)
.corner
  background-color: var(--ra-corner)
.accel
  background-color: var(--ra-accel)
.crash
  background-color: red
.topspeed
  background-color: var(--ra-white)
.idleTime
  background-color: var(--ra-lavender)
.speedCap
  background-color: var(--ra-speedcap)
.idleCap
  background-color: var(--ra-idlecap)

.vehicle-routes-page
  mat-paginator
    button.mat-mdc-icon-button
      padding: 0px !important
      @include sethw(24px)

.map-page
  .mat-expansion-panel
    box-shadow: none !important
    .mat-expansion-panel-header
      height: 24px !important
      padding: 0px
      // .mat-content
      .mat-expansion-indicator
        width: 20px
        &::before, &::after
          border-style: solid
          border-color: var(--ra-black) !important
    .mat-expansion-panel-body
      padding: 0px
  .panel-header
    button
      font-size: 12px
      font-weight: 400
.poi-selection
  background-color: #FF66CC !important
.fence-selection
  background-color: #7B5FE7 !important
.ra-alert
  // background-color: var(--ra-red) !important
  // color: var(--ra-white) !important
  // &.mat-mdc-snack-bar-container
  //   .mdc-snackbar__label
  //     color: var(--ra-white) !important
  // .mdc-snackbar__surface::before
  //   background-color: var(--ra-red) !important
  //   .mat-mdc-snack-bar-label
  //     &.mdc-snackbar__label
  //       color: var(--ra-white) !important

.selected-hl
  font-weight: bold

.ra-anim
  animation: grow 2s ease-in-out infinite

  @keyframes grow
    0%
      transform: scale(.75)
    50%
      transform: scale(1.25)
    100%
      transform: scale(.75)

  @keyframes float
    0%
      box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6)
      transform: translatey(0px)

    50%
      box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2)
      transform: translatey(-20px)

    100%
      box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6)
      transform: translatey(0px)

.ra-search-form
  input
    font-size: 14px !important
google-map.ra-mapss
  .map-container
    width: 100% !important
    height: 100% !important

.map-vis
  background-color: var(--ra-white)
  text-align: center

.ra-map-control
  @include sethw( 40px)
  background: none var(--ra-white)
  border: 0px
  margin: 10px
  padding: 0px
  cursor: pointer
  user-select: none
  border-radius: 2px
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px
  overflow: hidden

  svg
    margin: 8px

.map-content
  position: relative

  #ra-map-controls
    position: absolute
    right: 50px
    top: 130px
    background-color: var(--ra-white)
    border: 1px solid var(--ra-black)

@mixin diagonal-stripes($color1, $color2)
  background-image: repeating-linear-gradient( 45deg, $color1 25%, transparent 25%, transparent 75%, $color1 75%, $color1 ), repeating-linear-gradient( 45deg, $color1 25%, $color2 25%, $color2 75%, $color1 75%, $color1 )

.ra-page
  max-height: 100vh
  .ra-content
    @extend .bg-content
    min-height: calc(100vh - 50px)
    height: calc(100vh - 50px)
    overflow-y: scroll

  .ra-content-p
    @extend .ra-content
    @apply p-[10px] sm:p-[15px] md:p-[20px] lg:p-[30px]
  .ra-nonscroll
    max-height: calc(100vh - 50px) !important
    overflow-y: scroll
    @extend .bg-content

.bg-check
  background-color: #B68BDF
  @include diagonal-stripes(#dbd7d2, #B68BDF)
  background-position: 0 0, 10px 10px
  background-size: 20px 20px
.ra-pg
  min-height: calc(100vh - 50px)
  @extend .bg-pg
.bg-content
  background-color: var(--ra-lavender)
.bg-pg
  background-color: #fbfbfb

.ra-bg-white
  background-color: var(--ra-white)
.ra-bg-red
  background-color: var(--ra-red) !important
.ra-bg-lavender
  background-color: var(--ra-lavender)
.ra-bg-purple
  background-color: var(--ra-violet)
.ra-bg-black
  background-color: var(--ra-black)
.ra-bg-grey
  background-color: var(--ra-dark-grey)
.ra-bg-gray
  background-color: var(--ra-grey)
.ra-bg-orange
  background-color: var(--ra-orange)
.ra-bg-green
  background-color: var(--ra-green)

.ra-white-color
  color: var(--ra-white) !important
.ra-selected-card
  background-color: var(--ra-violet)
  color: var(--ra-white)

.ra-vh
  visibility: hidden
  height: 0px

.ra-bs
  box-shadow: 0px 0px 4px rgba(226, 224, 224, 0.5)

.ra-vv
  visibility: visible

.ra-control-options
  border: 1px solid var(--ra-black)

.ra-border
  border: 1px solid var(--ra-black)
  border-radius: 5px !important

.ra-heading
  font-size: 16px

.ra-sub
  font-size: 14px

.ra-hint
  font-size: 12px

#dashboard-fitler-table
  table
    box-shadow: none

.dashboard-content
  .ra-range
    .mdc-notched-outline__notch
      border-right: none
// Vehicle Chip styles
.vehicle-chip
  display: flex
  flex-flow: row
  gap: 16px
  position: relative

.vehicle-chip:hover
  cursor: pointer

.refresh-container
  position: absolute
  right: 0
  top: 0
  display: flex
  border: 2px solid var(--ra-violet)
  border-radius: 50%
  margin: 6px
.no-border
  border: none !important
.refresh
  width: 14px !important
  height: 14px !important
  font-size: 14px !important
  color: var(--ra-violet)

.container
  display: column

// Bottom Sheet
.bottom-sheet .mat-bottom-sheet-container
  width: 100vw !important
  max-width: none !important
  border-top-left-radius: 12px !important
  border-top-right-radius: 12px !important
  height: fit-content !important
  max-height: 90vh !important

// Simple mode mat slide toggle
.mat-mdc-slide-toggle.simple-mode
  --mdc-switch-selected-focus-state-layer-color: var(--ra-green) !important
  --mdc-switch-selected-handle-color: var(--ra-green) !important
  --mdc-switch-selected-hover-state-layer-color: var(--ra-green) !important
  --mdc-switch-selected-pressed-state-layer-color: var(--ra-green) !important
  --mdc-switch-selected-focus-handle-color: var(--ra-green) !important
  --mdc-switch-selected-hover-handle-color: var(--ra-green) !important
  --mdc-switch-selected-pressed-handle-color: var(--ra-green) !important
  --mdc-switch-selected-focus-track-color: var(--ra-light-green) !important
  --mdc-switch-selected-hover-track-color: var(--ra-light-green) !important
  --mdc-switch-selected-pressed-track-color: var(--ra-light-green) !important
  --mdc-switch-selected-track-color: var(--ra-light-green) !important
  --mdc-switch-disabled-selected-handle-color: #31d378 !important
  --mdc-switch-disabled-selected-icon-color: #130000 !important
  --mdc-switch-disabled-selected-track-color: #b75e5e !important
  --mdc-switch-disabled-unselected-handle-color: #cc8e8e !important
  --mdc-switch-disabled-unselected-icon-color: #b88f8f !important
  --mdc-switch-disabled-unselected-track-color: #b69494 !important

// Pre/Post Trip inspections mat expansion panel
.mat-expansion-panel-body
  padding: 0 !important

// PDF VIEWER
.viewer
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1)

.bottom-sheet-chat-user-selection .mat-bottom-sheet-container
  @extend .bg-content
  width: 100vw !important
  max-width: none !important
  border-top-left-radius: 12px !important
  border-top-right-radius: 12px !important
  height: fit-content !important
  max-height: 90vh !important
  min-height: 50vh !important

// Ngx Timepicker
.timepicker-overlay
  z-index: 10000000 !important

.timepicker-backdrop-overlay
  z-index: 10000000 !important
.ra-broder-r
  border-right: 1px dashed var(--ra-black)

.ra-broder-b
  border-bottom: 1px solid var(--ra-black)

.ra-black
  color: var(--ra-black)

.selected-activity
  font-weight: bold
  background-color: var(--ra-violet)
  color: var(--ra-white)

.ongoing-activity
  font-weight: bold
  background-color: var(--ra-green)
  color: var(--ra-white)

.ra-bg
  background-color: var(--ra-grey)
  color: var(--ra-white)

mat-slider.no-ripple
  .mat-mdc-slider-visual-thumb .mat-ripple
    height: 0 !important
    width: 0 !important

.resizable-mat-dialog-panel
  resize: both
  overflow: auto
  max-width: 90vw !important
  max-height: 70vh !important
  position: absolute !important
  filter: drop-shadow(0px 0px 3px var(--ra-violet)) !important

.green-text
  color: var(--ra-green)
.red-text
  color: var(--ra-red)
.orange-text
  color: var(--ra-orange)
.ra-link-text
  color: var(--ra-link)
  cursor: pointer

.pmrtbody
  height: calc(100vh - 218px)
  max-height: calc(100vh - 218px)
  overflow-y: scroll
  scrollbar-width: none
.pmrtbody::-webkit-scrollbar
  width: 0
  height: 0

.ra-icon
  @include sethw(24px)

.ra-table
  max-height: 50vh
  overflow-y: scroll
  color: var(--ra-black)
  table
    thead
      position: sticky
      top: 0px
      height: 20px !important
      border: 1px solid black
  th
    background-color: var(--ra-gray)
    border: 1px solid black
  td
    border: 1px solid black

.ra-card-view
  @extend .ra-card
  @apply p-[10px] rounded-[12px]

.ra-card-view-white
  @extend .ra-card-white
  @apply p-[10px] rounded-[12px]

.ra-f4
  @apply flex-[1_1_100%] md:flex-[1_1_50%] lg:flex-[1_1_33.33%] xl:flex-[1_1_25%]
  @apply md:max-w-[calc(50%-10px)] lg:max-w-[calc(33.33%-10px)] xl:max-w-[calc(25%-10px)]

.image-upload-carousel
  width: 100%
  overflow-x: auto
  gap: 10px
  border: 1px dashed var(--ra-grey)
  border-radius: 6px
  padding: 6px
  .image
    position: relative
    height: 100px
    width: 100px
    min-width: 100px
    border: 1px solid var(--ra-violet)
    border-radius: 6px
    img
      height: 100%
      width: 100%
      border-radius: 6px
      object-fit: cover
    .delete-icon
      position: absolute
      right: 0
      top: 0
      color: red
      width: 20px
      height: 20px
      font-size: 20px
    .delete-icon:hover
      cursor: pointer
      color: var(--ra-violet)

.ra-range
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline
    .mdc-notched-outline__notch, .mdc-notched-outline__leading, .mdc-notched-outline__trailing
      border-width: 0px !important
    // border-left: none !important
    // border-left-width: 0px !important
