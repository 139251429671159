// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$revit-primary: (
  50: #F2E3F2,
  100: #debae1,
  200: #c98ccd,
  300: #b35fba,
  400: #a33cab,
  500: #92169d,
  600: #851398,
  700: #730e90,
  800: #630a88,
  900: #45047A,
  contrast: (50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
  )
);
$revit-accent: (
  50: #feecf0,
  100: #f9ced8,
  200: #e79ca5,
  300: #dc7682,
  400: #e85766,
  500: #ee4651,
  600: #de3e4e,
  700: #cc3547,
  800: #bf2f3f,
  900: #af2735,
  contrast: (50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
  )
);

$revitauto-primary: mat.define-palette($revit-primary);
$revitauto-accent: mat.define-palette($revit-accent);
$revitauto-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$revitauto-theme: mat.define-light-theme((color: (primary: $revitauto-primary,
        accent: $revitauto-accent,
        warn: $revitauto-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($revitauto-theme);


html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "ra-popins" !important
}